import PropTypes from 'prop-types'
import { useState, useCallback, memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Box,
  Select,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'
import { getFoldersList } from '@tabeeb/modules/gallery/selectors'
import { prepareCreateSplatModel } from '../../actions'

const DEFAULT_FOLDER_ID = -1

const SplatSettingsDialog = ({ isOpen, closeDialog, createSplatModel, pageIds }) => {
  const [pageName, setPageName] = useState('')
  const [folderId, setFolderId] = useState(DEFAULT_FOLDER_ID)

  const enabled = useMemo(() => pageIds.length && isOpen, [isOpen, pageIds.length])
  const payload = useMemo(() => ({ pageIds }), [pageIds])

  const { response, loaded, loading } = useApiRequest({
    defaultResponse: '',
    request: prepareCreateSplatModel.request,
    payload,
    enabled,
  })

  const foldersList = useSelector(getFoldersList)

  const handleCreate = useCallback(() => {
    createSplatModel({
      FolderId: folderId === DEFAULT_FOLDER_ID ? null : folderId,
      PageName: pageName.length ? pageName : null,
    })

    closeDialog()
  }, [closeDialog, createSplatModel, folderId, pageName])

  useEffect(() => {
    if (loaded) {
      setPageName(response.PageName)
      setFolderId(response.FolderId ?? DEFAULT_FOLDER_ID)
    }
  }, [loaded, response])

  return (
    <Dialog open={isOpen} maxWidth='xs' fullWidth>
      <DialogTitle>Configure result page</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBlock: 2 }}>
          <TextField
            value={pageName}
            label='Page Name'
            variant='outlined'
            onChange={(e) => setPageName(e.target.value)}
            size='small'
            name='pageName'
            disabled={loading}
            fullWidth
          />
        </Box>
        <Box sx={{ marginBlock: 2 }}>
          <FormControl size='small' fullWidth>
            <InputLabel>Destination Folder</InputLabel>
            <Select
              sx={{ mt: 1 }}
              variant='outlined'
              value={folderId}
              onChange={(e) => setFolderId(e.target.value)}
              disabled={loading}
            >
              <MenuItem value={DEFAULT_FOLDER_ID}>Place in the Gallery</MenuItem>
              {foldersList.map((folder) => (
                <MenuItem value={folder.Id} key={folder.Id}>
                  {folder.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button onClick={handleCreate} color='primary' disabled={false} variant='contained'>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SplatSettingsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  createSplatModel: PropTypes.func.isRequired,
  pageIds: PropTypes.arrayOf(PropTypes.number),
}

export default memo(SplatSettingsDialog)
